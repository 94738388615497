import React, { useState } from "react";
import { Formik, Field, Form } from "formik";

function Login() {
    const [loading, setLoading] = useState(false);
    const [isRegistering, setIsRegistering] = useState(false);

    const oauthGoogle = async () => {
        setLoading(true);
        window.location.href = "https://api.geo-dz.com/auth/google";
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
            }}
        >
            <div className="login-form">
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <img
                        style={{ width: "10rem", marginBottom: "2rem", cursor: "pointer" }}
                        src="images/logo.svg"
                        alt="logo"
                    />
                </div>

                {isRegistering ? (
                    <>
                        <span className="login-title">Register your account</span>

                        <Formik
                            initialValues={{ identifier: "", password: "", confirmPassword: "" }}
                            onSubmit={async (values) => {
                                await new Promise((resolve) => setTimeout(resolve, 500));
                                fetch("https://api.geo-dz.com/api/register", {

                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify(values),
                                })
                                    .then((res) => res.json())
                                    .then((data) => {
                                        if (data.error) {
                                            alert(data.error);
                                        } else {
                                            sessionStorage.setItem("authToken", data.authToken);
                                            sessionStorage.setItem("username", data.username);
                                            sessionStorage.setItem("avatar", data.avatar);

                                            window.location.href = "/";
                                        }
                                    });

                            }}
                        >
                            <Form
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <Field className="finput" placeholder="Email" name="identifier" type="email" />
                                <Field className="finput" placeholder="Password" name="password" type="password" />
                                <Field
                                    className="finput"
                                    placeholder="Confirm Password"
                                    name="confirmPassword"
                                    type="password"
                                />
                                <div className="fbutton-container">
                                    <button className="fbutton" type="submit">
                                        Register
                                    </button>
                                </div>
                                <div className="method-control">
                                    <a
                                        onClick={oauthGoogle}
                                        className="method-action"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "1rem" }} height="24" viewBox="0 0 24 24" width="24">
                                            <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4" />
                                            <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853" />
                                            <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05" />
                                            <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335" />
                                            <path d="M1 1h22v22H1z" fill="none" />
                                        </svg>
                                        <span>Sign up with Google</span>
                                    </a>
                                </div>
                                <p>
                                    Already have an account?{" "}
                                    <a href="#" onClick={() => setIsRegistering(false)}>
                                        Login
                                    </a>
                                </p>
                            </Form>
                        </Formik>
                    </>
                ) : (
                    <>
                        <span className="login-title">Login to your account</span>

                        <Formik
                            initialValues={{ identifier: "", password: "" }}
                            onSubmit={async (values) => {
                                await new Promise((resolve) => setTimeout(resolve, 500));
                                
                                fetch("https://api.geo-dz.com/api/login", {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify(values),
                                })
                                    .then((res) => res.json())
                                    .then((data) => {
                                        if (data.success) {
                                            sessionStorage.setItem("authToken", data.authToken);
                                            sessionStorage.setItem("username", data.username);
                                            sessionStorage.setItem("avatar", data.picture);
                                            window.location.href = "/";
                                        } else {
                                            alert(data.message);
                                          
                                        }
                                    });

                            }}
                        >
                            <Form
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <Field className="finput" placeholder="Email" name="identifier" type="email" />
                                <Field className="finput" placeholder="Password" name="password" type="password" />
                                <div className="fbutton-container">
                                    <button className="fbutton" type="submit">
                                        Submit
                                    </button>
                                </div>
                                <div className="method-control">
                                    <a
                                        onClick={oauthGoogle}
                                        className="method-action"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" style={{ marginRight: "1rem" }} height="24" viewBox="0 0 24 24" width="24">
                                            <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4" />
                                            <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853" />
                                            <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05" />
                                            <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335" />
                                            <path d="M1 1h22v22H1z" fill="none" />
                                        </svg>
                                        <span>Sign in with Google</span>
                                    </a>
                                </div>
                                <p>
                                    Don’t have an account?{" "}
                                    <a href="#" onClick={() => setIsRegistering(true)}>
                                        Register
                                    </a>
                                </p>
                            </Form>
                        </Formik>
                    </>
                )}
            </div>
            <div className="login-left">
                <video
                    autoPlay
                    loop
                    muted
                    width="100"
                    height="100"
                    src="/earth.mp4"
                    type="video/mp4"
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                    }}
                />
            </div>
        </div>
    );
}

export default Login;
