import React, { useState, useEffect } from "react"

import { useParams, Link } from "react-router-dom"
// lottie



const Auth = () => {
  // get authToken, username, avatar from url
    const { authToken, username, avatar } = useParams()
    console.log(authToken)

    // when user is here : save to session + redirect to /

    useEffect(() => {
        sessionStorage.setItem("authToken", authToken)
        sessionStorage.setItem("username", username)
        // if decodeURIComponent is not null or "null"
        if (avatar !== "null" || avatar !== null) {
        sessionStorage.setItem("avatar", decodeURIComponent(avatar))
        }
      window.location.href = "/"
    }, [authToken, username, avatar])
    


  return (
    <>
      <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
        fontSize: "20px",
      }}
      
      >

            <img  
            src="/images/logo.svg"
            alt="logo"
            style={{ width: "600px", height: "100px" }}
            />
        </div>
    </>
  )
}

export default Auth