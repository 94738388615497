import { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from './pages/Home';
import Login from './pages/Login';
import StickyNavigation from './pages/StickyNavigation';
import Sidebar from './pages/dashboard/Sidebar';
import Map from './pages/dashboard/Map'; 
import Auth from './pages/Auth';
import logo from './logo.svg';
import Logout from './pages/Logout';
import './App.css';

// lessons
import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { useJsApiLoader } from '@react-google-maps/api';

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [authToken, setAuthToken] = useState(""); // Replace with your authentication token logic

  // Load the Google Maps API
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDnDFcEd8Z49W8O7H8eNFNQ2-4fK79LMiA",
    libraries: ["marker"]
  });

  useEffect(() => {
    // Retrieve the authentication token from async storage or session
    const storedAuthToken = sessionStorage.getItem("authToken");
    setAuthToken(storedAuthToken);
  }, []);

  const renderWithSidebar = (Component) => (
    <>
      <Sidebar />
      <main className="content">
        {Component}
      </main>
    </>
  );

  const renderWithStickyNav = (Component) => (
    <>
      <StickyNavigation />
      {Component}
    </>
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <ToastContainer />
          {isLoaded ? (
            authToken ? (
              <Routes>
                <Route path="/" element={renderWithSidebar(<Map />)} />
                <Route path="/map" element={renderWithSidebar(<Map />)} />
                <Route path="/payments" element={renderWithSidebar(<Map />)} />
                <Route path="/logout" element={<Logout />} />
              </Routes>
            ) : (
              <Routes>
                <Route path="/" element={renderWithStickyNav(<Home />)} />
                <Route path="/login" element={<Login />} />
                <Route path="/auth/:authToken/:username/:avatar" element={<Auth />} />
                <Route path="*" element={<Navigate to="/login" />} />
              </Routes>
            )
          ) : (
            <div>Loading...</div>
          )}
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
